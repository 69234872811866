<template>
  <div
    :style="
      `${
        $vuetify.breakpoint.smAndUp ? 'max-height: 80vh;' : 'max-height: 95vh; min-height: 88vh;'
      }; overflow: auto;`
    "
    class="expande-horizontal windows-style-content"
  >
    <v-flex xs12 md9>
      <div class="pa-3 expande-horizontal wrap">
        <v-flex v-if="$vuetify.breakpoint.smAndDown" xs12>
          <div class="expande-horizontal centraliza pt-8 wrap">
            <!-- <v-chip
              @click="configureDeliveryNote"
              small
              :color="$theme.primary"
              dark
              class="ma-1"
              >Nota de entrega
            </v-chip> -->
            <!-- <v-chip
              @click="openReceiptEmitter"
              small
              :color="$theme.primary"
              dark
              class="ma-1"
              >Recibo
            </v-chip> -->
            <v-chip
              @click="sendToPdv(get_gestordepedido._id)"
              small
              :color="$theme.primary"
              dark
              class="ma-1"
              >pdv
            </v-chip>
             <!-- <v-chip
              @click="printReceipt(get_gestordepedido)"
              small
              :color="$theme.primary"
              dark
              class="ma-1"
              >Imprimir
            </v-chip> -->
            <v-chip
              v-if="!get_gestordepedido.external_movimentation"
              @click="prepareDelivery(get_gestordepedido)"
              small
              :color="$theme.primary"
              dark
              class="ma-1"
              >Agendamento
            </v-chip>
            <v-chip
              @click="initPayment"
              small
              :color="$theme.primary"
              dark
              class="ma-1"
              v-if="get_gestordepedido.status_do_pagamento === 'pendente'"
              >pagamento
            </v-chip>
          </div>
        </v-flex>
        <MembersPlugin
          origin="sale"
          :dataOrigin="{ ...get_gestordepedido, state: 'progress-view' }"
        />
        <TagPlugin
          :dataOrigin="get_gestordepedido.compra.tags || []"
          :dataSale="get_gestordepedido"
        />
      </div>
      <files
        :v-model="get_gestordepedido.files"
        :value="get_gestordepedido.files || []"
        :sale="get_gestordepedido"
        @input="updateFiles($event)"
      />
      <view-external-movimentation
        v-if="get_gestordepedido.external_movimentation"
        :external_movimentation="get_gestordepedido.external_movimentation"
        :openEvent="editDelivery"
      />
      <!-- <PlateViewer /> -->
      <payment-logs
        v-if="parseFloat(get_gestordepedido.compra.total_recebido || 0) > 0"
        :payments="get_gestordepedido.historico_de_pagamento || []"
      />
      <in-preparation-stage />
      <sale-log />
    </v-flex>
    <v-flex v-if="$vuetify.breakpoint.lgAndUp" class="pa-3" md3>
      <span class="fonte fonteMini grey--text"> Opções: </span>
      <v-list nav color="transparent" class="pa-0 ma-0" dense>
        <v-list-item
          @click="sendToPdv(get_gestordepedido._id)"
          style="border-radius: 6px; margin-bottom: 6px; background: #e5e5e5;"
        >
          <v-icon size="18" class="mr-2">mdi-calculator</v-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span class="fonte">Abrir no PDV</span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon color="grey" size="21">mdi-open-in-new</v-icon>
          </v-list-item-action>
        </v-list-item>
        <!-- <v-list-item
          @click="configureDeliveryNote"
          style="border-radius: 6px; margin-bottom: 6px; background: #e5e5e5;"
        >
          <v-icon size="18" class="mr-2">mdi-cloud-print-outline</v-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span class="fonte">Imprimir Nota de entrega</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item
          @click="openReceiptEmitter"
          style="border-radius: 6px; margin-bottom: 6px; background: #e5e5e5;"
        >
          <v-icon size="18" class="mr-2">mdi-printer-outline</v-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span class="fonte">Imprimir recibo</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item
          @click="printReceipt(get_gestordepedido)"
          style="border-radius: 6px; margin-bottom: 6px; background: #e5e5e5;"
        >
          <v-icon size="18" class="mr-2">mdi-receipt-outline</v-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span class="fonte">Imprimir Orçamento</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item
          v-if="!get_gestordepedido.external_movimentation"
          @click="prepareDelivery(get_gestordepedido)"
          style="border-radius: 6px; margin-bottom: 6px; background: #e5e5e5;"
        >
          <v-icon size="18" class="mr-2">mdi-calendar</v-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span class="fonte">Criar agendamento</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item
          @click="initPayment"
          style="border-radius: 6px; margin-bottom: 6px; background: #e5e5e5;"
        >
          <v-icon
            v-if="get_gestordepedido.status_do_pagamento === 'pendente'"
            size="18"
            class="mr-2"
            >mdi-cash-register</v-icon
          >
          <v-icon color="green" v-else size="18" class="mr-2"
            >mdi-check-outline</v-icon
          >
          <v-list-item-content>
            <v-list-item-title>
              <span
                v-if="get_gestordepedido.status_do_pagamento === 'pendente'"
                class="fonte"
                >Informar Pagamento</span
              >
              <span
                v-if="get_gestordepedido.status_do_pagamento === 'pago'"
                class="fonte green--text font-weight-bold"
                >Paga</span
              >
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="fonte fonteMini text--green">
                {{
                  $helper.formataSaldo(
                    get_gestordepedido.compra.total_recebido || "0"
                  )
                }}
                /
                {{
                  $helper.formataSaldo(get_gestordepedido.compra.preco_total)
                }}
              </span>
              <!-- <v-progress-linear
                :color="
                  parseInt(get_gestordepedido.compra.total_recebido) ==
                  parseInt(get_gestordepedido.compra.preco_total)
                    ? 'green'
                    : 'orange'
                "
                :height="3"
                :value="get_gestordepedido.compra.total_recebido"
                :max="get_gestordepedido.compra.preco_total"
              ></v-progress-linear> -->
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-chip x-small class="fonte text-weight-bold" v-if="get_gestordepedido.compra.desconto" color="orange lighten-2" style="border-radius: 3px;">
                Desconto: {{
                  $helper.formataSaldo(
                    get_gestordepedido.compra.desconto
                  )
                }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="confirmCancel"
          style="border-radius: 6px; margin-bottom: 6px; background: #e5e5e5;"
          v-if="
            get_gestordepedido.status_do_pagamento === 'pendente' &&
              get_gestordepedido.status !== 'cancelado' &&
              get_gestordepedido.status !== 'concluído'
          "
        >
          <v-icon size="18" class="mr-2">mdi-cancel</v-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span class="fonte">Cancelar a venda</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- <v-autocomplete 
        solo
        dense
        flat
        background-color="#e5e5e5"
        :color="$theme.primary"
        hide-details
        :items="kanbans"
        item-text="name"
        return-object
        prepend-inner-icon="mdi-send"
        label="Mover"
      ></v-autocomplete> -->
      <!-- <CosmosPluginStore context="sale" /> -->
      <!-- <cosmos-plugin-store context="sale"/> -->
    </v-flex>
    <EventDialog ref="eventDialog" origin="sale-body" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CosmosPluginStore from "@/apps/CosmosPluginStore/index.vue";
import MembersPlugin from "@/apps/CosmosPluginStore/plugins/members/index.vue";
import TagPlugin from "@/apps/CosmosPluginStore/plugins/tag/index.vue";
import InPreparationStage from "../../components/SaleStages/in_preparation/index.vue";
import ViewExternalMovimentation from "../../components/ViewExternalMovimentation/index.vue";
import PaymentLogs from "../../components/paymentLogs/index.vue";
import SaleLog from "./saleLog.vue";
import Files from "./saleFiles.vue";
import EventDialog from "@/apps/ExternalMovimentation/components/EventDialog.vue";
import PlateViewer from '@/apps/27#Caixas/components/plateViewer/index.vue';

export default {
  props: [
    "openReceiptEmitter",
    "printReceipt",
    "configureDeliveryNote",
    "confirmCancel",
    "sendToPdv",
    "initPayment"
  ],
  components: {
    CosmosPluginStore,
    PaymentLogs,
    MembersPlugin,
    TagPlugin,
    InPreparationStage,
    SaleLog,
    EventDialog,
    ViewExternalMovimentation,
    Files,
    PlateViewer
  },
  computed: {
    ...mapGetters(["get_gestordepedido"])
  },
  data() {
    return {
      kanbans: JSON.parse(localStorage.getItem("kanbans")) || []
    };
  },
  methods: {
    ...mapActions(["fecha_modal_view_gestordepedido", "update_transaction_files"]),
    addTagOnSale(tag) {
      console.log("bateu", tag);
      // this.$store.dispatch("addTagOnSale", tag);
    },
    updateFiles(files) {
      this.update_transaction_files(files)
        .then(res => {
          console.log(res);
          this.$forceUpdate();
        })
    },
    removeTagFromSale(tag) {
      console.log("bateuw", tag);
      // this.$store.dispatch("removeTagFromSale");
    },
    prepareDelivery(sale) {
      this.$refs.eventDialog.open(sale, "sale");
    },
    editDelivery() {
      this.$refs.eventDialog.open(
        this.get_gestordepedido.external_movimentation,
        "open-event"
      );
    }
  }
};
</script>
