<template>
  <v-flex xs12>
    <div style="background: #f2f2f2;" class="wrap expande-horizontal pb-6">
      <v-flex
        v-if="
          get_gestordepedido.compra && get_gestordepedido.compra.produtos.length
        "
        xs12
      >
        <v-list color="transparent" dense class="fonte" light>
          <v-list-item>
            <v-avatar class="ml-1 mr-3" color="#FF9800">
              <v-icon color="white">
                mdi-alarm-light-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold"
                >Compra aguardando</v-list-item-title
              >
              <v-list-item-subtitle>
                Aceite ou recuse abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-flex xs12 class="pa-3">
          <div
            class="expande-horizontal centraliza column"
            style="min-height: 5vh;"
          >
            <div class="expande-horizontal column centraliza wrap">
              <v-flex xs10 md4>
                <LottieAnimation
                  class="pa-6 pb-0"
                  ref="cancel_stage"
                  :loop="true"
                  :animationData="
                    require('@/apps/shared/assets/saved_stage.json')
                  "
                />
              </v-flex>
              <v-flex class="pa-3 pt-0" xs10 md8>
                <div class="fonte column expande-horizontal centraliza">
                  <!-- <h3 style="color: #333333;">Aceite ou Recuse abaixo</h3> -->
                  <span class="text-center"> </span>
                </div>
              </v-flex>
              <v-flex xs10 md8>
                <div class="fonte expande-horizontal centraliza">
                  <!-- <v-btn
                    large
                    dark
                    @click.prevent="refuseSale"
                    rounded
                    class="mx-2 font-weight-bold"
                    :color="$theme.primary"
                  >
                    Recusar
                  </v-btn> -->
                  <v-btn
                    large
                    dark
                    @click.prevent="nextStatus(false, 'em andamento')"
                    rounded
                    class="mx-2 font-weight-bold"
                    :color="$theme.primary"
                    >Aceitar</v-btn
                  >
                </div>
                <!-- Arrasta para a coluna em andamento para aceitar -->
                <div class="expande-horizontal centraliza">

                  <v-btn
                    large
                    dark
                    @click.prevent="refuseSale"
                    rounded
                    text
                    class="mx-2 font-weight-bold"
                    :color="$theme.secondary"
                  >
                    Recusar
                  </v-btn>
                </div>
              </v-flex>
            </div>
          </div>
        </v-flex>
      </v-flex>
      <v-flex xs12>
        <v-divider></v-divider>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex";
import LottieAnimation from "lottie-web-vue";

export default {
  props: ["nextStatus", "refuseSale"],
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters(["get_gestordepedido"])
  }
};
</script>
