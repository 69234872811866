<template>
  <div class="expande-horizontal pt-3 wrap">
    <v-flex v-for="sale in filteredSales" xs6 :key="sale._id">
      <SaleItemMini :statusType="statusType" :sale="sale" />
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SaleItemMini from "./SaleItemMini.vue";
export default {
  name: "SaleList",
  props: ["filteredSales", "statusType"],
  components: {
    SaleItemMini
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters(["get_transacoes"])
  }
};
</script>
