<template>
  <layout :rotas="rotas" :title="$route.name">
    <div class="expande-horizontal pa-3 fonte">
      <!-- <v-btn
        @click="ver_todos"
        v-if="mostrar_todos"
        :color="$theme.primary"
        class="elevation-0 font-weight-bold"
        dark
        >voltar</v-btn
      >
      <v-btn @click="ver_todos" v-else color="grey" class="elevation-0 font-weight-bold"
      dark
        >Ver todos</v-btn
      > -->
      <v-btn
        @click="$router.push('/garcom')"
        :color="$theme.primary"
        class="elevation-0 font-weight-bold"
        dark
        >Ir para Comanda</v-btn
      >
    </div>
    <SalesListMySales
      v-if="filteredSales.length"
      :filteredSales="filteredSales"
      :filters="filter"
      :statusType="status_type"
    />
    <div
      v-else
      class="expande-horizontal pb-6 fonte centraliza column"
      style="min-height: 30vh; border: 1px solid #e0e0e0; border-radius: 6px;"
    >
      <!-- <v-flex xs8 md3>
        <LottieAnimation
          class="pa-6"
          ref="saved_stage"
          :loop="true"
          :animationData="require('@/apps/shared/assets/empty_sales.json')"
        />
      </v-flex> -->
      <h3 class="grey--text font-weight-light">Não encontramos nada!</h3>
    </div>
    <ViewSale :receipt="receipt" :payment_sys_config="payment_sys_config" />
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ViewSale from "../components/ViewSale";
import SalesListMySales from "../components/SalesListMySales";
import Filters from "../components/Filters";
import LottieAnimation from "lottie-web-vue";

export default {
  data() {
    return {
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        },
        {
          name: "Meus pedidos",
          path: "/pedidos-garcom"
        }
      ],
      mostrar_todos: false,
      payment_sys_config: {},
      receipt: {
        show_my_address: { active: true },
        show_my_logo: { active: true },
        show_my_qrcode: { active: true },
        show_client_details: { active: true },
        show_header_text: { active: true, text: "" },
        show_footer_text: { active: true, text: "" }
      },
      filter: {
        type: "balcao",
        status: "",
        search: ""
      },
      status_type: {
        delivery: [
          // {
          //   icon: "mdi-content-save-outline",
          //   text: "Salvo",
          //   value: "salvo",
          //   color: "#AEEA00"
          // },
          // {
          //   icon: "mdi-alarm-light-outline",
          //   text: "Aguardando",
          //   value: "aguardando",
          //   color: "#FF9800"
          // },
          {
            icon: "mdi-clock-outline",
            text: "Em preparo",
            value: "em preparo",
            color: "#8BC34A"
          },
          {
            icon: "mdi-rocket-outline",
            text: "Pronto",
            value: "pronto",
            color: "#2196F3"
          },
          {
            icon: "mdi-rocket-launch-outline",
            text: "Saiu p entrega",
            value: "despachado",
            color: "#2196F3"
          }
          // {
          //   icon: "mdi-check-circle-outline",
          //   text: "Finalizado",
          //   value: "concluído",
          //   color: "#4CAF50"
          // }
          // {
          //   icon: "mdi-close-outline",
          //   text: "Cancelado",
          //   value: "cancelado",
          //   color: "#F44336"
          // }
        ],
        mesa: [
          // {
          //   icon: "mdi-content-save-outline",
          //   text: "Salvo",
          //   value: "salvo",
          //   color: "#AEEA00"
          // },
          // {
          //   icon: "mdi-alarm-light-outline",
          //   text: "Aguardando",
          //   value: "aguardando",
          //   color: "#FF9800"
          // },
          {
            icon: "mdi-clock-outline",
            text: "Em preparo",
            value: "em preparo",
            color: "#8BC34A"
          },
          {
            icon: "mdi-clock-outline",
            text: "Pronto",
            value: "pronto",
            color: "#8BC34A"
          },
          {
            icon: "mdi-clock-outline",
            text: "Em Consumo",
            value: "em consumo",
            color: "#8BC34A"
          }
          // {
          //   icon: "mdi-rocket-outline",
          //   text: "Finalizado",
          //   value: "concluído",
          //   color: "#4CAF50"
          // }
          // {
          //   icon: "mdi-close-outline",
          //   text: "Cancelado",
          //   value: "cancelado",
          //   color: "#F44336"
          // }
        ],
        balcao: [
          // {
          //   icon: "mdi-content-save-outline",
          //   text: "Salvo",
          //   value: "salvo",
          //   color: "#AEEA00"
          // },
          {
            icon: "mdi-clock-outline",
            text: "Em preparo",
            value: "em preparo",
            color: "#8BC34A"
          },
          {
            icon: "mdi-basket-outline",
            text: "Pronto",
            value: "pronto",
            color: "#2196F3"
          }
          // {
          //   icon: "mdi-check-circle-outline",
          //   text: "Finalizado",
          //   value: "concluído",
          //   color: "#4CAF50"
          // }
          // {
          //   icon: "mdi-close-outline",
          //   text: "Cancelado",
          //   value: "cancelado",
          //   color: "#F44336"
          // }
        ]
      }
    };
  },
  components: {
    ViewSale,
    SalesListMySales,
    Filters,
    LottieAnimation
  },
  watch: {
    "filter.type": function(val) {
      localStorage.last_type_manager = val;
    }
  },
  computed: {
    ...mapGetters(["getLoggedUser", "get_gestordepedidos"]),
    filteredSales() {
      let filteredSales = this.get_gestordepedidos.docs;

      if (!this.mostrar_todos) {
        filteredSales = this.get_gestordepedidos.docs.filter(
          sale =>
            sale.caixa.funcionario &&
            sale.caixa.funcionario._id === this.getLoggedUser._id
        );
      }

      return filteredSales;
      if (!this.filter.search) {
        // filteredSales = this.get_gestordepedidos.docs.filter(
        //   sale =>
        //     sale.caixa.funcionario &&
        //     sale.caixa.funcionario._id === this.getLoggedUser._id
        // );
        // if (this.filter.type) {
        //   filteredSales = this.get_gestordepedidos.docs.filter(
        //     sale => sale.compra.tipo === this.filter.type
        //   );
        // }
        // if (this.filter.status) {
        //   const searchStr = this.filter.status.toLowerCase();
        //   filteredSales = filteredSales.filter(sale => {
        //     const statusMatch =
        //       sale.status_do_pedido.toLowerCase().indexOf(searchStr) >= 0;
        //     return statusMatch;
        //   });
        // }
      } else {
        if (this.filter.search) {
          const searchStr = this.filter.search.toLowerCase();

          filteredSales = filteredSales.filter(sale => {
            const id_transacao = sale.id_transacao === parseInt(searchStr);
            const client = sale.compra.cliente
              ? sale.compra.cliente.nome.toLowerCase().indexOf(searchStr) >= -1
              : false;
            return client || id_transacao;
          });
        }
      }

      return filteredSales;
    }
  },
  methods: {
    ver_todos() {
      this.mostrar_todos = !this.mostrar_todos;
    },
    ...mapActions(["listar_gestordepedidos"]),
    setStatus(status) {
      this.filter = {
        ...this.filter,
        status
      };
      this.$forceUpdate();
    },
    filterByStatus(status) {
      return this.get_gestordepedidos.docs.filter(
        sale =>
          sale.status_do_pedido.toLowerCase() === status.toLowerCase() &&
          sale.compra.tipo.toLowerCase() === this.filter.type.toLowerCase()
      ).length;
    },
    getConfigPaymentSys() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-payment-sys")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.payment_sys_config = res.data.payment_sys_config;
        })
        .catch(e => {});
    },
    getConfigReceipt() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-receipt")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.receipt = res.data.receipt;
        })
        .catch(e => {});
    }
  },
  created() {
    this.listar_gestordepedidos();
    this.getConfigPaymentSys();
    this.getConfigReceipt();
    this.filter.type = localStorage.last_type_manager;
  }
};
</script>
