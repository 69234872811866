<template>
  <v-flex xs12>
    <div class="border-view-sale wrap expande-horizontal pb-6">
      <v-flex
        v-if="
          get_gestordepedido.compra && get_gestordepedido.compra.produtos.length
        "
        xs12
      >
        <v-list v-if="!hiddenAction" dense class="fonte" light>
          <v-list-item>
            <v-avatar class="ml-1 mr-3" :color="$theme.secondary">
              <v-icon color="white">
                mdi-checkbox-multiple-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title
                >Marque os itens que estão prontos</v-list-item-title
              >
              <v-list-item-subtitle>
                Assim que terminar de marcar, poderá avançar com a venda!
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div v-if="!hiddenAction" class=" expande-horizontal">
          <v-progress-linear
            :color="$theme.secondary"
            height="3"
            value="0"
            striped
          ></v-progress-linear>
        </div>
        <v-simple-table
          dark
          style="witdh: 100%; min-height: 200px; overflow: auto; background: #f0f0f0;"
          dense
        >
          <template v-slot:default>
            <thead style="background: #EBEBEB; height: 40px;">
              <tr style="background: #EBEBEB; border: 1px solid #f0f0f0;">
                <td
                  v-if="!hiddenAction"
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Preparo
                </td>
                <td
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Quant
                </td>
                <td
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Item
                </td>
                <td
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Preço
                </td>
                <td class="font-weight-bold fonte" style="color: #878686;">
                  Subtotal
                </td>
              </tr>
            </thead>
            <tbody>
              <template
                style="border-top: 1px solid #D2D4CF;"
                v-for="(item, i) in get_gestordepedido.compra.produtos"
              >
                <tr
                  class="mt-3"
                  @click="openModalAddProduct(item, `${i}`)"
                  style="background: #f0f0f0; cursor: pointer; border: 1px solid #f0f0f0;"
                  :key="`product-in-list-${i}`"
                >
                  <td
                    v-if="!hiddenAction"
                    style="color: #878686; width: 10px; border-top: 1px dashed #D2D4CF;"
                    class="fonte fonte-bold"
                  >
                    <v-checkbox
                      v-model="item.is_done"
                      light
                      @click="saveItemDone"
                      :disabled="item.is_done"
                      :color="$theme.secondary"
                    ></v-checkbox>
                  </td>
                  <td
                    class="fonte fonte-bold"
                    style="color: #878686; border-top: 1px dashed #D2D4CF;"
                    @click.prevent="abre_modal_view_caixa(item)"
                  >
                    {{ item.quantity }}x
                  </td>
                  <td
                    class="fonte fonte-bold"
                    style="color: #494949; border-top: 1px dashed #D2D4CF;"
                    @click.prevent="abre_modal_view_caixa(item)"
                  >
                    {{ item.nome }}
                  </td>

                  <td
                    class="fonte green--text fonte-bold"
                    style="color: #878686; border-top: 1px dashed #D2D4CF;"
                    @click.prevent="abre_modal_view_caixa(item)"
                  >
                    <!-- {{ $helper.formataSaldo(item.preco_com_markup) }} -->
                    <div
                      v-if="item.offer_selected"
                      class="fonte item-price-offer item-price-offer-selected"
                      :style="`background: ${$theme.primary}`"
                    >
                      <span>{{ $helper.formataSaldo(item.offer_selected.value || 0) }}</span>
                      <span class="ml-2 fonteMini white--text">{{
                        item.offer_selected.description
                      }}</span>
                    </div>
                  </td>
                  <td
                    class="fonte green--text fonte-bold"
                    style="color: #878686; border-top: 1px dashed #D2D4CF;"
                    @click.prevent="abre_modal_view_caixa(item)"
                  >
                    {{ $helper.formataSaldo(item.seller_price) }}
                  </td>
                </tr>
                <template v-for="(adicional, i) in item.adicionais">
                  <tr
                    style="background: #f0f0f0; border: none;"
                    v-for="(item, index) in adicional.itens"
                    :key="
                      `item-adicional-${adicional.descricao_personalizada}-${index}`
                    "
                    v-show="
                      item.quantidade_adicionada &&
                        item.quantidade_adicionada >= 1
                    "
                  >
                    <td
                      @click.prevent="abre_modal_view_caixa(item)"
                      style="color: #878686; width: 10px"
                      class="fonte fonte-micro"
                    >
                      <!-- # {{ item.code ? item.code : "N/A" }} -->
                    </td>
                    <td
                      class="fonte fonte-micro"
                      style="color: #878686;"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ item.quantidade_adicionada }}x{{ item.descricao_personalizada }}
                    </td>
                    <td
                      class="fonte fonte-micro"
                      style="color: #878686"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                    </td>
                    <!-- <td
                      class="fonte fonte-micro"
                      style="color: #878686"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ $helper.formataSaldo(item.preco) }}
                    </td> -->
                    <!-- <td
                      class="fonte fonte-micro"
                      style="color: #878686"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{
                        $helper.formataSaldo(
                          item.quantidade_adicionada * parseFloat(item.preco)
                        )
                      }}
                    </td> -->
                  </tr>
                </template>
                <tr
                  light
                  v-show="item.obs"
                  style="background: #f0f0f0; border-bottom: 3px dashed #363636;"
                  :key="`item-obs-${i}`"
                  xs12
                >
                  <td class="fonte fonte-micro" style="color: #878686">
                    Obs:
                  </td>
                  <td class="fonte fonte-micro" style="color: #878686">
                    {{ item.obs }}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
      <div
        v-else
        style="height: 200px;"
        class="expande-horizontal centraliza pb-6 column"
      >
        <img width="60" src="img/logo_mini.svg" class="mb-3" />

        <!-- <v-icon color="grey" size="64">mdi-cart-check</v-icon> -->
        <h4 class="fonte grey--text">
          Não há produtos nesta compra
        </h4>
      </div>
      <div v-if="allProductIsMarkeds && !hiddenAction" class="expande-horizontal pa-3">
        <v-btn
          @click.prevent="nextStatus(false, 'em preparo')"
          :color="$theme.secondary"
          dark
          rounded
          class="font-weight-bold"
        >
          Marcar como pronto
        </v-btn>
        <!-- <v-btn
          rounded
          @click.prevent="cancelSale"
          :color="$theme.primary"
          dark
          class="ml-1"
          text
          x-small
        >
          Cancelar
        </v-btn> -->
      </div>
    </div>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InPreparationStage",
  props: ["nextStatus", "hiddenAction"],
  computed: {
    ...mapGetters(["get_gestordepedido"]),
    allProductIsMarkeds() {
      let products = this.get_gestordepedido.compra.produtos;
      let markeds = products.filter(p => p.is_done);
      console.log("products", products);
      console.log("markeds", markeds);
      if (products.length === markeds.length) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions(["atualizar_gestordepedido"]),
    openModalAddProduct() {
      return;
    },
    saveItemDone() {
      setTimeout(() => {
        this.atualizar_gestordepedido();
      }, 300);
    },
  }
};
</script>

<style lang="scss">
.item-price-offer-selected {
  background: #6b25d4 !important;
  color: #fff !important;
  span {
    color: #fff !important;
  }
}
</style>
