<template>
  <v-dialog fullscreen transition="slide-x-transition" v-model="modal">
    <div class="expande-horizontal expande-vertical-vh vitrify centraliza">
      <v-flex xs12 md10>
        <div class="animate__animated expande-horizontal column">
          <div class="expande-horizontal centraliza wrap py-6 pb-0">
            <v-flex xs12 md8>
              <div class="expande-horizontal wrap">
                <v-card
                  color=""
                  class="expande-horizontal"
                  style="min-height: 77vh; border-radius: 15px;"
                >
                  <div class="expande-horizontal">
                    <v-flex
                      v-if="$vuetify.breakpoint.smAndUp"
                      class="pa-6"
                      xs12
                      md6
                    >
                      <div
                        style="min-height: 60vh;"
                        class="expande-horizontal pa-6 centraliza"
                      >
                        <LottieAnimation
                          v-if="loading === 'loading_rocket'"
                          class="pa-6"
                          ref="anim"
                          :loop="true"
                          :animationData="
                            require('@/apps/shared/assets/loading_rocket.json')
                          "
                        />
                        <LottieAnimation
                          v-if="loading === 'success'"
                          class="pa-6"
                          ref="anim2"
                          :animationData="
                            require('@/apps/shared/assets/activate_account_success.json')
                          "
                        />
                        <LottieAnimation
                          v-if="loading === 'man_typing'"
                          :loop="true"
                          class="pa-6 animate__fadeInUp"
                          ref="anim3"
                          :animationData="
                            require('@/apps/shared/assets/expense.json')
                          "
                        />
                      </div>
                    </v-flex>
                    <v-flex class="pa-6" xs12 md6>
                      <v-window v-model="step">
                        <v-window-item :value="1">
                          <div
                            style="min-height: 57vh;"
                            transition="fade"
                            class="pt-6 mt-3 expande-horizontal centraliza fonte animate__fadeInLeft animate__delay-2s wrap"
                          >
                            <v-flex class="pa-5 pb-2" xs12>
                              <div
                                class="expande-horizontal column animate__fadeIn"
                              >
                                <h1 class="fonte" style="color: #333;">
                                  {{
                                    $helper.formataSaldo(
                                      parseFloat(
                                        get_gestordepedido.compra.preco_total
                                      ) -
                                        (get_gestordepedido.compra
                                          .total_recebido || 0)
                                    )
                                  }}
                                </h1>
                                <span class="fonte grey--text"
                                  >Escolha a forma de pagamento...</span
                                >
                              </div>
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                              <v-flex xs12 class="">
                                <v-list
                                  v-if="loading === 'man_typing'"
                                  nav
                                  two-line
                                  class="animate__fadeInRight animate__animated pa-0 ma-0 pb-6"
                                >
                                  <v-list-item
                                    :class="
                                      indexByArrow === 0
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    @click="selectPaymentMethod('SCN')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="#333"
                                      >mdi-cash</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        SCN
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    :class="
                                      indexByArrow === 1
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    v-if="payment_sys_config.money.active"
                                    @click="selectPaymentMethod('dinheiro')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="green"
                                      >mdi-cash</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        1 Dinheiro
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    :class="
                                      indexByArrow === 2
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    v-if="payment_sys_config.pix.active"
                                    @click="selectPaymentMethod('pix')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="green"
                                      >mdi-cash</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        2 Pix
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    :class="
                                      indexByArrow === 3
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    v-if="payment_sys_config.card.active"
                                    @click="selectPaymentMethod('credito')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="green"
                                      >mdi-credit-card</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        3 Crédito
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    :class="
                                      indexByArrow === 4
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    v-if="payment_sys_config.debit.active"
                                    @click="selectPaymentMethod('debito')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="green"
                                      >mdi-credit-card</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        4 Débito
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                <v-btn
                                  @click="close"
                                  dark
                                  text
                                  class="fonte animate__delay-2s animate__fadeIn grey--text text-capitalize mt-6"
                                  >Fechar
                                  <v-icon size="16" class="mt-1" color="grey"
                                    >mdi-close</v-icon
                                  >
                                </v-btn>
                              </v-flex>
                            </v-flex>
                          </div>
                        </v-window-item>
                        <v-window-item :value="2">
                          <div
                            transition="fade"
                            v-if="loading === 'man_typing'"
                            style="min-height: 57vh;"
                            class="mt-3 expande-horizontal centraliza fonte animate__fadeInLeft wrap"
                          >
                            <v-flex class="pa-3" xs12>
                              <div class="expande-horizontal column">
                                <h2 style="color: #1173ff;">
                                  {{ methodsConverted[this.indexByArrow] }}
                                </h2>
                                <span class="grey--text fonte">
                                  Informe o valor abaixo
                                </span>
                              </div>
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                              <div
                                class="expande-horizontal wrap"
                                style="max-height: 300px; overflow: auto; overflow-x: hidden;"
                              >
                                <div class="pb-6">
                                  <h1 style="color: #1173ff;" class="fonte">
                                    {{
                                      $helper.formataSaldo(
                                        valueToPayInSelectedMethod || 0
                                      )
                                    }}
                                  </h1>
                                </div>
                                <v-flex xs12>
                                  <v-text-field
                                    prefix="R$"
                                    label="Informe o valor"
                                    hint="Informe todo ou parte do valor"
                                    clearable
                                    v-mask="[
                                      '#.##',
                                      '##.##',
                                      '###.##',
                                      '####.##',
                                      '#####.##'
                                    ]"
                                    v-model="valueToPayInSelectedMethod"
                                  ></v-text-field>
                                </v-flex>
                              </div>
                              <div class="expande-horizontal">
                                <v-btn
                                  @click="step = 1"
                                  dark
                                  text
                                  class="fonte animate__delay-2s mr-2 animate__fadeIn grey--text text-capitalize mt-3"
                                >
                                  <v-icon size="16" class="mt-1" color="grey"
                                    >mdi-chevron-left</v-icon
                                  >
                                  voltar
                                </v-btn>
                                <v-btn
                                  @click="reportValueReceived"
                                  dark
                                  :color="$theme.third"
                                  class="fonte animate__delay-2s animate__fadeIn white--text text-capitalize mt-3"
                                >
                                  Pagar
                                </v-btn>
                                <v-spacer></v-spacer>
                              </div>
                            </v-flex>
                          </div>
                        </v-window-item>
                        <v-window-item :value="3">
                          <div
                            transition="fade"
                            v-if="loading === 'success'"
                            style="min-height: 17vh;"
                            class="mt-3 expande-horizontal centraliza fonte animate__fadeInLeft wrap"
                          >
                            <v-flex class="pa-3" xs12>
                              <div class="expande-horizontal column">
                                <h2 style="color: #1173ff;">
                                  Pagamento
                                </h2>
                                <span class="grey--text fonte">
                                  Esta compra foi integralmente paga
                                </span>
                              </div>
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                              <div
                                class="expande-horizontal wrap"
                                style="max-height: 300px; overflow: auto; overflow-x: hidden;"
                              >
                                <div class="pb-6">
                                  <h1 style="color: #1173ff;" class="fonte">
                                    {{
                                      $helper.formataSaldo(
                                        get_gestordepedido.compra.total_recebido
                                      )
                                    }}
                                  </h1>
                                  <h3
                                    class="font-weight-light orange--text"
                                    v-if="get_gestordepedido.compra.troco"
                                  >
                                    troco:
                                    {{
                                      $helper.formataSaldo(
                                        get_gestordepedido.compra.troco
                                      )
                                    }}
                                  </h3>
                                </div>
                                <v-flex class="fonte" xs12>
                                  <v-list nav class="pa-0 ma-0" dense>
                                    <template
                                      v-for="(payment,
                                      i) in get_gestordepedido.historico_de_pagamento"
                                    >
                                      <v-list-item
                                        v-show="payment.valor"
                                        :key="`payment-${i}`"
                                      >
                                        <v-avatar
                                          size="30"
                                          class="mr-2"
                                          :color="$theme.third"
                                        >
                                          <span
                                            class="white--text font-weight-bold"
                                          >
                                            {{ i }}
                                          </span>
                                        </v-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            class="green--text font-weight-bold"
                                          >
                                            {{
                                              $helper.formataSaldo(
                                                payment.valor
                                              )
                                            }}
                                            em {{ payment.forma_de_pagamento }}
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                          </v-list-item-subtitle>
                                          <v-list-item-subtitle>
                                            {{
                                              $moment(payment.data).format(
                                                "DD/MM [às] HH:mm"
                                              )
                                            }}
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider
                                        :key="`divider--${i}`"
                                      ></v-divider>
                                    </template>
                                  </v-list>
                                </v-flex>
                              </div>
                              <div class="expande-horizontal">
                                <v-btn
                                  @click="close"
                                  dark
                                  :color="$theme.third"
                                  class="fonte animate__delay-2s animate__fadeIn white--text text-capitalize mt-3"
                                >
                                  Fechar
                                </v-btn>
                                <v-spacer></v-spacer>
                              </div>
                            </v-flex>
                          </div>
                        </v-window-item>
                        <v-window-item :value="4">
                          <div
                            style="min-height: 40vh;"
                            class="expande-horizontal wrap centraliza"
                          >
                            <v-flex class="pa-3" xs12>
                              <div class="expande-horizontal column">
                                <h2 style="color: #1173ff;">
                                  Cliente
                                </h2>
                                <span class="grey--text fonte">
                                  Informe o cpf do cliente SCN
                                </span>
                              </div>
                            </v-flex>
                            <v-text-field
                              label="CPF"
                              v-mask="['###.###.###-##']"
                              placeholder="ex: 000.000.000-00"
                              ref="cpfScn"
                              @keydown.enter.prevent="searchClientScn"
                              :disabled="loadingScn ? true : false"
                              outlined
                              v-model="cpf_cliente_revolution"
                              large
                              rounded
                              background-color="#f2f2f2"
                            ></v-text-field>
                            <div class="expande-horizontal column celtraliza">
                              <v-btn
                                class="text-none"
                                color="#216892"
                                rounded
                                :loading="loadingScn"
                                size="large"
                                variant="flat"
                                @click="searchClientScn"
                              >
                                <div
                                  class="blue-gradient-text"
                                  style="max-width: 100%; overflow: hidden;"
                                >
                                  <span class="golden-font"
                                    >Buscar Cliente SCN</span
                                  >
                                  <v-icon class="ml-2 golden-font"
                                    >mdi-account-circle-outline</v-icon
                                  >
                                </div>
                              </v-btn>
                              <v-btn
                                @click="step = '3'"
                                dark
                                text
                                :color="$theme.third"
                                class="fonte animate__delay-2s animate__fadeIn grey--text text-capitalize mt-3"
                              >
                                Voltar
                              </v-btn>
                            </div>
                          </div>
                        </v-window-item>
                        <v-window-item :value="5">
                          <div
                            style="min-height: 40vh;"
                            class="expande-horizontal wrap centraliza"
                          >
                            <v-flex class="pa-3" xs12>
                              <div class="expande-horizontal column">
                                <h2 style="color: #1173ff;">
                                  Código do Autenticador
                                </h2>
                                <span class="fonte">
                                  Código gerado no app de
                                  {{ cliente_revolution.name }}
                                </span>
                              </div>
                            </v-flex>
                            <v-text-field
                              label="Código"
                              v-mask="['######']"
                              placeholder="ex: 123456"
                              @keyup.enter.prevent="
                                autenticarPagamentoSCNRevolution
                              "
                              autofocus
                              ref="inputCodigo"
                              outlined
                              :disabled="loadingScn"
                              v-model="codigo_cliente_revolution"
                              large
                              rounded
                              background-color="#f2f2f2"
                            ></v-text-field>
                            <div class="expande-horizontal column celtraliza">
                              <v-btn
                                class="text-none"
                                color="#216892"
                                rounded
                                size="large"
                                :loading="loadingScn"
                                variant="flat"
                                @click="autenticarPagamentoSCNRevolution"
                              >
                                <div
                                  class="blue-gradient-text"
                                  style="max-width: 100%; overflow: hidden;"
                                >
                                  <span class="golden-font">Pagar agora</span>
                                  <v-icon class="ml-2 golden-font"
                                    >mdi-contactless-payment-circle-outline</v-icon
                                  >
                                </div>
                              </v-btn>
                              <v-btn
                                @click="step = '3'"
                                dark
                                text
                                :color="$theme.third"
                                class="fonte animate__delay-2s animate__fadeIn grey--text text-capitalize mt-3"
                              >
                                Voltar
                              </v-btn>
                            </div>
                          </div>
                        </v-window-item>
                      </v-window>
                    </v-flex>
                  </div>
                </v-card>
              </div>
            </v-flex>
          </div>
        </div>
      </v-flex>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LottieAnimation from "lottie-web-vue";
import { EventBus } from "@/main.js";
// import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  // components: { ModalSendArchive },
  props: {
    // updatePayment: {
    //   type: Function,
    //   default: () => {}
    // },
    payment_sys_config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      cpf_cliente_revolution: "",
      cliente_revolution: {},
      methods: ["SCN", "dinheiro", "pix", "credito", "debito"],
      methodsConverted: ["SCN", "Dinheiro", "Pix", "Crédito", "Débito"],
      modal: false,
      valueToPayInSelectedMethod: "",
      loading: "",
      loadingScn: false,
      step: 1,
      indexByArrow: 0,
      remainingToPay: false,
      type: "", //payment_method_selected
      codigo_cliente_revolution: ""
    };
  },
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters([
      "get_invoice",
      "getLoggedUser",
      "get_gestordepedido",
      "get_gestordepedidos"
    ]),
    placeholderDescription() {
      let description = "";
      if (!this.get_invoice.expense_type) {
        description = "Ex: Pagamento do Luiz...";
      }
      if (this.get_invoice.expense_type) {
        description = this.get_invoice.expense_type.description;
      }
      return description;
    }
  },
  watch: {
    "get_invoice.saved": function(val) {
      if (val) {
        this.setLoading("success");
        this.step = 4;
      }
    }
  },
  methods: {
    searchClientScn() {
      this.loadingScn = true;
      this.$run("box/get-destination-user", {
        cpf: this.cpf_cliente_revolution
      })
        .then(res => {
          this.cliente_revolution = res.data;
          this.step = 5;
          setTimeout(() => {
            this.$refs.inputCodigo.focus();
          }, 300);
        })
        .catch(() => alert("Cliente não encontrado"))
        .finally(() => (this.loadingScn = false));
    },
    autenticarPagamentoSCNRevolution() {
      this.loadingScn = true;
      this.$run("box/validate-otp-code", {
        user: this.cliente_revolution._id,
        stablishment: this.get_gestordepedido.tenant,
        _id: this.get_gestordepedido._id,
        code: this.codigo_cliente_revolution,
        value: this.valueToPayInSelectedMethod
      })
        .then(res => {
          this.valueToPayInSelectedMethod = "";
          this.type = "";
          this.$store.dispatch("disableLoading");
          this.$store.commit("set_gestordepedido", res.data);
          if (res.data.status_do_pagamento === "pago") {
            this.step = 3;
            this.setLoading("success");
          } else {
            this.step = 1;
          }
          const docs = this.get_gestordepedidos.docs;
          const updatedDoc = res.data;
          const newDocs = [];
          docs.map(docTransaction => {
            if (docTransaction._id === updatedDoc._id) {
              newDocs.push(updatedDoc);
            } else {
              newDocs.push(docTransaction);
            }
          });
          this.$store.commit("set_gestordepedidos", {
            ...this.get_gestordepedidos,
            docs: newDocs
          });
          this.cliente_revolution = {};
          this.codigo_cliente_revolution = "";
          this.$forceUpdate();
        })
        .catch(e => {
          alert("Código inválido, tente novamente");
          this.codigo_cliente_revolution = "";
          setTimeout(() => {
            this.$refs.inputCodigo.focus();
          }, 300);
        })
        .finally(() => (this.loadingScn = false));
    },
    selectPaymentMethod(method) {
      this.type = method;
      let remainingToPay = 0;
      // valor que ainda falta para pagar
      if (this.valueToPayInSelectedMethod > 0) {
        this.get_gestordepedido.compra[this.type] = parseFloat(
          this.valueToPayInSelectedMethod
        ).toFixed(2);
      }
      if (this.get_gestordepedido.compra[this.type]) {
        if (this.get_gestordepedido.compra[this.type] > 0) {
          this.get_gestordepedido.compra.total_recebido =
            this.get_gestordepedido.compra.total_recebido -
            parseFloat(this.get_gestordepedido.compra[this.type]);
        }
      }
      remainingToPay =
        parseFloat(this.get_gestordepedido.compra.preco_total) -
        (this.get_gestordepedido.compra.total_recebido || 0);

      if (remainingToPay === 0) {
        this.step = 3;
      } else {
        this.valueToPayInSelectedMethod = remainingToPay.toFixed(2);
        //step 4 = Fluxo de pagamento scn Revolution
        if (this.type === "SCN") {
          this.step = 4;
          setTimeout(() => {
            this.$refs.cpfScn.focus();
          }, 300)
        } else {
          this.step = 2;
        }
      }
    },
    reportValueReceived() {
      if (this.valueToPayInSelectedMethod > 0) {
        this.get_gestordepedido.compra[this.type] = parseFloat(
          this.valueToPayInSelectedMethod
        ).toFixed(2);
        this.pay();
      }
    },
    pay() {
      this.updatePayment();
      this.open();
    },
    updatePayment() {
      this.$store.dispatch("enableLoading");
      this.$run("caixas/update-payment", this.get_gestordepedido)
        .then(res => {
          this.valueToPayInSelectedMethod = "";
          this.type = "";
          this.$store.dispatch("disableLoading");
          this.$store.commit("set_gestordepedido", res.data);
          if (res.data.status_do_pagamento === "pago") {
            this.step = 3;
            this.setLoading("success");
          } else {
            this.step = 1;
          }
          const docs = this.get_gestordepedidos.docs;
          const updatedDoc = res.data;
          const newDocs = [];
          docs.map(docTransaction => {
            if (docTransaction._id === updatedDoc._id) {
              newDocs.push(updatedDoc);
            } else {
              newDocs.push(docTransaction);
            }
          });
          this.$store.commit("set_gestordepedidos", {
            ...this.get_gestordepedidos,
            docs: newDocs
          });
          this.cliente_revolution = {};
          this.codigo_cliente_revolution = "";
          this.$forceUpdate();
        })
        .catch(e => {
          this.$store.dispatch("disableLoading");
          console.log(e);
        });
    },
    setLoading(state) {
      this.loading = "";
      setTimeout(() => {
        this.loading = state;
      }, 300);
    },
    open() {
      this.modal = false;
      this.modal = true;

      if (this.get_gestordepedido.status_do_pagamento === "pago") {
        this.setLoading("success");
        this.step = 3;
      } else {
        setTimeout(() => {
          this.setLoading("man_typing");
          this.step = 1;
        }, 100);
      }
    },
    close() {
      this.modal = false;
    },
    stepBack() {
      if (this.step === 1) {
        this.close();
      } else {
        this.step--;
      }
    }
  },
  mounted() {
    EventBus.$on("ArrowUp", () => {
      if (this.indexByArrow > 0) {
        this.indexByArrow--;
      }
    });
    EventBus.$on("ArrowDown", () => {
      if (this.indexByArrow < 4) {
        this.indexByArrow++;
      }
    });
    EventBus.$on("ArrowLeft", () => {
      this.stepBack();
    });
    // EventBus.$on("ArrowRight", () => {
    //   if (this.step === 1) {
    //     this.selectPaymentMethod(this.methods[this.indexByArrow]);
    //   }
    // });
    EventBus.$on("Enter", () => {
      if (this.step === 1) {
        this.selectPaymentMethod(this.methods[this.indexByArrow]);
      }
    });
  }
};
</script>

<style>
.paymentMethodPreSelected {
  background: #f0f0f0;
  border-radius: 6px;
}
</style>
