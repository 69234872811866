<template>
  <div
    class="animate__fadeIn animate__animated card-sale-item white--text fonte elevation-1"
  >
    <div class="expande-horizontal font-weight-bold centraliza pa-3 pb-0">
      <!-- <v-icon size="16" :color="$theme.secondary">mdi-file-outline</v-icon> -->
      {{ sale.id_transacao }}
      {{ sale.compra.tipo | TypeFilter }}
      {{ sale.compra.mesa ? sale.compra.mesa.number : "" }}
    </div>
    <v-list dark class="pa-0" color="transparent" dense>
      <v-list-item class="px-3 white--text ma-0" @click="abre_pdv(sale)">
        <v-list-item-content>
          <v-list-item-title class="fonte white--text">
            {{
              sale.compra.cliente
                ? sale.compra.cliente.nome
                : "Cliente não informado"
            }}</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-3 white--text ma-0" @click="abre_pdv(sale)">
        <v-list-item-content>
          <v-list-item-title class="fonte white--text">
            <!-- <v-icon :color="$theme.secondary" size="16"
              >mdi-account-circle</v-icon
            > -->
            {{ sale.status_do_pedido }}</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-divider style="background-color: getColorStatus();"></v-divider>
      <v-expansion-panels v-if="false" flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="fonte grey--text">
            <v-list-item class="pa-0 ma-0">
              <v-list-item-content>
                <v-list-item-title>
                  Veja o andamento
                </v-list-item-title>
                <v-list-item-subtitle>
                  Status atual:
                  {{
                    sale.historico_de_status[
                      sale.historico_de_status.length - 1
                    ].status
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-timeline align-top dense>
              <v-timeline-item
                :color="$theme.secondary"
                small
                :key="status"
                v-for="status in sale.historico_de_status || []"
              >
                <span class="fonte"> {{ status.status }}</span>
                <span class="grey--text fonte">
                  ({{ $moment(status.date).format("DD/MM [às] HH:mm") }})
                </span>
              </v-timeline-item>
            </v-timeline>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel style="border-top: 1px solid #333">
          <v-expansion-panel-header class="fonte grey--text">
            <v-list-item class="pa-0 ma-0">
              <v-list-item-content>
                <v-list-item-title>
                  Histórico de pagamento
                </v-list-item-title>
                <v-list-item-subtitle>
                  Status atual:
                  {{
                    sale.historico_de_status[
                      sale.historico_de_status.length - 1
                    ].status
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-timeline align-top dense>
              <v-timeline-item
                :color="$theme.secondary"
                small
                :key="status"
                v-for="status in sale.historico_de_pagamento || []"
              >
                <span class="fonte"> {{ status.status }}</span>
                <span class="grey--text fonte">
                  ({{ $moment(status.date).format("DD/MM [às] HH:mm") }})
                </span>
              </v-timeline-item>
            </v-timeline>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-list-item class="white--text" @click="abre_pdv(sale)">
        <v-list-item-content class="white--text">
          <!-- <v-list-item-title class="fonte text-capitalize font-weight-bold">
            {{ $moment(sale.created_at).fromNow() }}
          </v-list-item-title> -->
          <v-list-item-subtitle class="fonte fonteMini">
            {{ $moment(sale.created_at).format("DD/MM") }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="fonte fonteMini">
            {{ $moment(sale.created_at).format("HH:mm") }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="fonte white--text">
          <h5>{{ $helper.formataSaldo(sale.compra.preco_total) }}</h5>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    sale: {
      type: Object,
      required: true
    },
    statusType: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  filters: {
    TypeFilter(value) {
      if (value === "delivery") {
        return "Delivery";
      } else if (value === "mesa") {
        return "Mesa";
      } else if (value === "balcao") {
        return "Balcão";
      } else if (value === "retirada") {
        return "retirada";
      }
    }
  },
  computed: {
    images() {
      let images = [];
      this.sale.compra.produtos.forEach(image => {
        if (image.imagens && image.imagens.length > 0) {
          image.imagens.map(img => {
            images.push(img);
          });
        }
        images.push(image);
      });
      return images;
    }
  },
  methods: {
    getColorStatus() {
      if (this.statusType) {
        const status = this.statusType.filter(
          status => status.value === this.sale.status_do_pedido.toLowerCase()
        );
        if (status.length > 0) {
          return status[0].color;
        }
        return "#000";
      }
    },
    ...mapActions(["listar_gestordepedidos", "abre_modal_view_gestordepedido"]),
    abre_pdv(compra) {
      this.$router.push(`/garcom?t_id=${compra._id}`);
    },
    update_status(compra, status) {
      this.$run("caixas/atualizar-status-do-pedido", {
        ...compra,
        novo_status: status.value
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `Status do pedido atualizado para ${status.text}`,
            timeout: 6000
          });
          this.listar_gestordepedidos();
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    }
  }
};
</script>

<style>
.card-sale-item {
  margin: 10px;
  border-radius: 6px;
  background: #e1673c;
}
</style>
